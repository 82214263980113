import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsFillPlayFill } from "react-icons/bs";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import VideoCard from "../components/VideoCards/VideoCard";
import Loading from "../components/commen/Loading";
import { getMovieDetails } from "../utils/apis";
const ThirdPart = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  const [similarMovies, setSimilarMovies] = React.useState([]);
  const [description, setDescription] = React.useState(false);
  const [access, setAccess] = React.useState({
    access: false,
  });

  const { id: id2 } = useParams();

  const getMovieDetailsHandler = async () => {
    setLoading(true);
    const [success, response] = await getMovieDetails(id2);
    if (success) {
      setData(response);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getMovieDetailsHandler();
  }, [id2]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="flex-1 md:px-32">
      <div className="relative aspect-video w-full object-cover md:aspect-[10/4]">
        {/* <div className="absolute top-0 right-0 left-0 z-10 h-[] bg-primary-100"></div> */}

        {!access.access && (!data?.trailer || data.trailer === "undefined") ? (
          <img
            src={data?.horizontalBanner}
            alt="poster"
            className="h-full w-full bg-black object-contain"
          />
        ) : (
          <ReactPlayer
            url={access?.access ? data?.url : data?.trailer}
            controls
            width="100%"
            height="100%"
          />
        )}
      </div>

      <div className="  flex flex-row flex-wrap items-center justify-between gap-6 rounded py-3 px-8 text-white md:bg-primary-100 md:py-1.5 md:text-black ">
        <div className="flex flex-row flex-wrap items-center gap-6">
          <p className="text-sm font-[600]">{data?.name}</p>
          <div className="flex gap-1">
            {data?.genres?.map((item, index) => (
              <>
                <p className="text-xs font-[400]">{item.name}</p>
                {data?.genres?.length > 1 &&
                  data?.genres?.length !== index + 1 && (
                    <p className="text-xs font-[400]">|</p>
                  )}
              </>
            ))}
          </div>
          <p className="text-xs font-[400]">{data?.duration}</p>
          <p className="text-xs font-[400]">{data?.language}</p>
          <p className="text-xs font-[400]">{data?.releaseYear}</p>
        </div>
        <div className="flex gap-6">
          {!access.access && (
            <button
              onClick={() => {
                setAccess({ access: true });
              }}
              className="flex items-center gap-1 rounded bg-primary-200 px-5 py-2 text-xs font-semibold uppercase text-white shadow-primary2 hover:shadow-xl "
            >
              <BsFillPlayFill className="text-xl" />
              Watch Now
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col  gap-3 py-4 px-8 text-white md:py-8">
        <div className="flex flex-col gap-3">
          <div className="text-xs">
            Rating:
            <br />
            <div className="mt-1 flex gap-1">
              {new Array(5)
                .fill(0)
                .map((item, index) =>
                  +data?.rating > index ? (
                    <AiFillStar className="text-primary-100" />
                  ) : (
                    <AiOutlineStar className="text-primary-100" />
                  )
                )}
            </div>
          </div>
          <p className="text-xs">
            Description:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.description?.slice(
                0,
                description ? data?.description?.length : 100
              )}
              <button
                className="ml-1 text-xs font-[400] text-primary-100"
                onClick={() => setDescription(!description)}
              >
                See {description ? "less" : "more"}
              </button>
            </span>
          </p>
          <p className="text-xs">
            Age-Limit:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.ageLimit}
            </span>
          </p>
        </div>

        {data?.cast?.length > 0 && (
          <>
            <p className="text-xs">Cast:</p>
            <div className="flex flex-wrap gap-4 text-white">
              {data.cast?.map((item, index) => (
                <div className="flex flex-col  gap-2 text-xs">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="h-44 w-28 rounded-md object-cover"
                  />
                  <div>
                    <p>{item.name}</p>
                    <p className="text-text">{item.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {similarMovies.length > 0 && (
        <VideoCard
          data={{
            name: "You might also like",
            imageType: "Vertical",
            content: similarMovies.map((item, index) => ({
              kind: "movie",
              item: item,
            })),
          }}
        />
      )}

      {/* {watch && (
        <MovieModal
          data={data}
          setWatch={setWatch}
          RentHandler={RentHandler}
          access={access}
        />
      )} */}
    </div>
  );
};

export default ThirdPart;
