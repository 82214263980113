import React from "react";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/logo.jpg";
import useStore from "../../../utils/store";
const MobileNavbar = ({ home, thirdParty }) => {
  const activeCategory = useStore((state) => state.activeCategory);
  const setActiveCategory = useStore((state) => state.setActiveCategory);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  return (
    <nav className=" grid grid-cols-3 items-center bg-black px-6 py-4 md:hidden">
      <img
        src={Logo}
        alt="logo"
        className=" mr-auto w-16"
        onClick={() => {
          navigate(thirdParty ? "/third-party" : "/");
        }}
      />
      <div className="mx-auto flex items-center gap-6">
        {home?.map((item) => (
          <button
            key={item._id}
            onClick={() => {
              setActiveCategory(item._id);
            }}
            className={`flex items-center gap-2 rounded text-sm ${
              activeCategory === item._id
                ? ` text-primary-100 `
                : ` text-text hover:text-white`
            }   `}
          >
            {item.name}
          </button>
        ))}
      </div>
      {!thirdParty && (
        <div className="ml-auto flex gap-2">
          <button
            onClick={() => {
              navigate("/search");
            }}
            className="rounded-full bg-white bg-opacity-25 p-3 text-xl text-text"
          >
            <BsSearch />
          </button>
          {isLoggedIn ? (
            <button
              onClick={() => {
                navigate("/my-account");
              }}
              className="flex items-center gap-2 rounded text-sm text-text hover:text-white"
            >
              Profile
            </button>
          ) : (
            <button
              onClick={() => {
                navigate("/login");
              }}
              className="flex items-center gap-2 rounded text-sm text-text hover:text-white"
            >
              Login
            </button>
          )}
        </div>
      )}
    </nav>
  );
};

export default MobileNavbar;
