import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { loginUser, signUpUser } from "../../utils/apis";
import { auth } from "../../utils/firebaseconfig";
import useStore from "../../utils/store";

const OAuth = ({ setOtpSent, setEmail, setSignUp, setLoading }) => {
  const loginState = useStore((state) => state.login);
  const navigate = useNavigate();
  const googleLogin = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const [success, response] = await loginUser({
        email: result.user.email,
      });

      if (success) {
        loginState({
          ...response,
          loginWith: "email",
        });
        navigate("/", {
          replace: true,
        });
      }
      if (response.response.data.message === "user doesn't exist") {
        const formData = new FormData();
        formData.append("email", result.user.email);
        formData.append("name", result.user.displayName);
        formData.append("imageUrl", result.user.photoURL);
        const [success, response] = await signUpUser(formData);
        if (success) {
          loginState({
            ...response,
            loginWith: "email",
          });
          navigate("/", {
            replace: true,
          });
        }
      }
    } catch (err) {}
    setLoading(false);
  };

  return (
    <>
      {/* <div className="flex w-full items-center gap-2">
        <div className="h-[1px] flex-1  bg-text"></div>
        <p className="text-[10px] font-semibold text-text  ">
          Or you login with
        </p>
        <div className="h-[1px] flex-1 bg-text"></div>
      </div> */}
      <div className="flex items-center justify-center gap-6  ">
        {/* <button className=" rounded-full  bg-white p-2  text-xl text-[#3b5998] transition-all hover:px-6  ">
          <FaFacebookF />
        </button> */}
        <button
          onClick={googleLogin}
          className="flex   items-center gap-2 rounded bg-white  py-2 px-3 font-mono text-sm font-semibold  uppercase   transition-all hover:bg-black hover:bg-opacity-60 hover:text-white hover:shadow-lg hover:backdrop-blur-sm"
        >
          <FcGoogle className="text-xl" /> <p>Sign-in with Google</p>
        </button>
        {/* <button className=" text-black rounded-full text-xl  transition-all p-2 bg-white hover:px-6">
          <FaApple />
        </button> */}
      </div>
    </>
  );
};

export default OAuth;
