import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { BiMovie } from "react-icons/bi";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { Autoplay } from "swiper";

const Banners = ({ data, thirdParty }) => {
  const swiperRef = React.useRef(null);
  const [active, setActive] = React.useState(0);
  const navigate = useNavigate();
  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={0}
      slidesPerView={1.4}
      centeredSlides={true}
      onSlideChange={() => {
        setActive(swiperRef.current.realIndex);
      }}
      onSwiper={(swiper) => {}}
      onBeforeInit={(swiper) => {
        swiperRef.current = swiper;
      }}
      //interaction to stop

      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
      loop
    >
      {data
        ?.filter((item) => item.isEnable)
        ?.map((item, index) => (
          <SwiperSlide key={index} className="relative">
            <div className="relative flex p-4 md:p-6">
              {item.origin === "Image" && (
                <div className=" absolute left-24 top-1/2 hidden w-36 -translate-y-1/2 flex-col  justify-center gap-2 md:flex ">
                  <h1 className="text-5xl font-semibold text-white">
                    {item?.link?.item?.name}
                  </h1>
                  <div className="flex gap-2">
                    {item?.link?.item?.genres?.map((i, index) => {
                      return (
                        <>
                          <p className="text-xs text-text">{i.name}</p>
                          {index !== item?.link?.item?.genres?.length - 1 && (
                            <div className="my-auto h-1 w-1 rounded-full bg-slate-300" />
                          )}
                        </>
                      );
                    })}
                  </div>

                  <button
                    onClick={() => {
                      if (thirdParty) {
                        if (item?.link?.kind === "movie") {
                          navigate(`/third-party/${item?.link?.item?.slug}`);
                        } else {
                          navigate(`/third-party/${item?.link?.item?.slug}`);
                        }
                      } else {
                        if (item?.link?.kind === "movie") {
                          navigate(`/movie/${item?.link?.item?.slug}`);
                        } else {
                          navigate(`/series/${item?.link?.item?.slug}`);
                        }
                      }
                    }}
                    className=" flex items-center justify-center gap-1 rounded bg-primary-100 px-2 py-2 text-xs font-semibold text-white transition-all hover:bg-primary2 hover:text-black"
                  >
                    <BiMovie className="text-lg" />
                    Watch Now
                  </button>
                </div>
              )}

              {item.origin === "Image" ? (
                <img
                  src={item.image}
                  alt={item._id}
                  className={`
                  mx-auto
                  ${active === index ? "scale-105" : "scale-95"}
                  aspect-[16/9] w-full rounded-xl bg-black object-cover transition-all`}
                />
              ) : (
                <div className=" mx-auto aspect-[16/9]  w-full  rounded-xl object-cover md:aspect-[16/6]  ">
                  <ReactPlayer
                    url={item.image}
                    width="100%"
                    height="100%"
                    controls
                    playing={active === index}
                    loop
                    muted
                    className="rounded-xl object-cover"
                  />
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      <div className="absolute top-[45%] right-2 z-10">
        <button
          className="rounded-full bg-primary-100 bg-opacity-30 p-2 shadow-xl"
          onClick={() => {
            swiperRef.current?.slideNext();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
      <div className="absolute top-[45%] left-2 z-10">
        <button
          className="rounded-full bg-primary-100  bg-opacity-30 p-2 shadow-xl"
          onClick={() => {
            swiperRef.current?.slidePrev();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
      </div>
    </Swiper>
  );
};

export default Banners;
