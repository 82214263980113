import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

const VideoCard = ({ data, episode, onClick, thirdParty }) => {
  const navigate = useNavigate();
  const swiperRef = React.useRef(null);

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);

  return (
    <div className="mx-2 md:mx-5">
      <div className="flex items-center justify-between">
        <h1 className="pl-2 text-lg font-semibold text-white">{data?.name}</h1>
        {data?.content?.length > 5 && (
          <button className="rounded-lg bg-primary-100 px-2 py-1 text-xs font-semibold text-white transition-all hover:bg-primary-200">
            View All
          </button>
        )}
      </div>
      <Swiper
        spaceBetween={8}
        slidesPerView={
          data?.imageType === "Vertical"
            ? windowWidth > 768
              ? windowWidth > 1120
                ? windowWidth > 1280
                  ? windowWidth > 1440
                    ? windowWidth > 1600
                      ? 9.2
                      : 8
                    : 7
                  : 6.2
                : 5.9
              : 2.2
            : data?.imageType === "Long Horizontal"
            ? 1
            : windowWidth > 768
            ? windowWidth > 1120
              ? windowWidth > 1280
                ? windowWidth > 1440
                  ? windowWidth > 1600
                    ? 6
                    : 5
                  : 4.5
                : 3.8
              : 4
            : 1.4
        }
        slidesPerGroup={1}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        speed={600}
      >
        {data?.content
          ?.filter((item) => item?.item?.isEnable === true)
          ?.map((item, index) => (
            <>
              <SwiperSlide
                key={index}
                className=" cursor-pointer py-3 px-2"
                onClick={() => {
                  if (episode) {
                    onClick(index);
                  } else {
                    if (thirdParty) {
                      item.kind === "movie"
                        ? navigate(`/third-party/${item.item.slug}`)
                        : navigate(`/third-party/${item.item.slug}`);
                    } else {
                      item.kind === "movie"
                        ? navigate(`/movie/${item.item.slug}`)
                        : navigate(`/series/${item.item.slug}`);
                    }
                  }
                }}
              >
                <img
                  src={
                    data.imageType === "Vertical"
                      ? item.item.verticalBanner
                      : item.item.horizontalBanner
                  }
                  alt={item.item.name}
                  className={`${
                    data.imageType === "Vertical"
                      ? "aspect-[11/16] hover:scale-105 "
                      : data.imageType === "Long Horizontal"
                      ? "h-44 md:h-[27rem] xl:h-[30rem] 2xl:h-[36rem] 3xl:h-[43rem]"
                      : "aspect-[16/9]  hover:scale-105 "
                  } w-full rounded-lg   transition-all `}
                />
                <p className="mt-1 ml-1 text-[10px] text-text">
                  {item.item.name}
                </p>
              </SwiperSlide>
            </>
          ))}
        {((data.imageType === "Vertical" && data?.content?.length > 5) ||
          (data.imageType !== "Vertical" && data?.content?.length > 4) ||
          (data.imageType === "Long Horizontal" &&
            data?.content?.length > 1)) &&
          windowWidth > 768 && (
            // arrow icon
            <>
              <div className="absolute top-[38%] right-0 z-10">
                <button
                  className="rounded-full bg-primary-100 p-2"
                  onClick={() => {
                    swiperRef.current?.slideNext();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
              <div className="absolute top-[38%] left-0 z-10">
                <button
                  className="rounded-full bg-primary-100 p-2"
                  onClick={() => {
                    swiperRef.current?.slidePrev();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
              </div>
            </>
          )}
      </Swiper>
    </div>
  );
};

export default VideoCard;
