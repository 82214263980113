import React from "react";
import { TbDeviceWatchOff, TbDeviceWatchStats } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import VideoCard from "../components/VideoCards/VideoCard";
import Loading from "../components/commen/Loading";
import {
  addToWatchList,
  checkAccess,
  checkUserWatchlist,
  getSeriesDetails,
  getSimilarSeries,
  removeFromWatchList,
  rentASeries,
} from "../utils/apis";

import { BsFillPlayFill } from "react-icons/bs";
import ReactPlayer from "react-player";
import MovieModal from "../components/MovieModal";
import useStore from "../utils/store";
const SeriesDetails = () => {
  const [data, setData] = React.useState([]);

  const { id: id2 } = useParams();
  const [id, setId] = React.useState(0);
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const [similarSeries, setSimilarSeries] = React.useState([]);
  const [currentEpisode, setCurrentEpisode] = React.useState(0);
  const [description, setDescription] = React.useState(false);
  const [isWatchList, setIsWatchList] = React.useState(false);
  const [watch, setWatch] = React.useState(false);
  const navigate = useNavigate();
  const [access, setAccess] = React.useState({
    access: false,
  });
  const getSeriesDetailsHandler = async () => {
    setLoading(true);
    const [success, response] = await getSeriesDetails(id);
    if (success) {
      setData(response);
    }
    setLoading(false);
  };

  const getSimilarSeriesHandler = async () => {
    setLoading(true);
    const [success, response] = await getSimilarSeries(id);
    if (success) {
      setSimilarSeries(response);
      setId(response._id);
    }
    setLoading(false);
  };

  const checkWatchList = async () => {
    setLoading(true);
    const [success, response] = await checkUserWatchlist(user.token, id);
    if (success) {
      setIsWatchList(response.isExist);
    }
    setLoading(false);
  };

  const addToWatchListHandler = async () => {
    setLoading(true);
    const [success, response] = await addToWatchList(user.token, {
      id,
      type: "series",
    });
    if (success) {
      await checkWatchList();
    }
    setLoading(false);
  };

  const removeFromWatchListHanlder = async () => {
    setLoading(true);
    const [success, response] = await removeFromWatchList(user.token, {
      id,
    });
    if (success) {
      checkWatchList();
    }
    setLoading(false);
  };

  const checkAccessHandler = async () => {
    const [success, response] = await checkAccess(user.token);
    if (success) {
      if (data?.isFree) {
        setAccess({
          access: true,
        });
      } else {
        setAccess({
          access: response?.subscriptionValid,
        });
      }
    }
  };

  const RentHandler = async () => {
    setLoading(true);
    const [success, response] = await rentASeries(user.token, {
      type: "Series",
      seriesId: id,
    });

    if (success) {
      setWatch(false);
      checkAccessHandler();
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getSeriesDetailsHandler();
    getSimilarSeriesHandler();
    checkWatchList();
    checkAccessHandler();
  }, [id2]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className=" md:px-32">
      <div className="relative aspect-video w-full object-cover md:aspect-[10/4]">
        {!access.access && data?.trailer === "undefined" ? (
          <img
            src={data?.poster}
            alt="poster"
            className="h-full w-full object-cover"
          />
        ) : (
          <ReactPlayer
            url={
              access.access
                ? data?.episodes[currentEpisode]?.url
                : data?.trailer
            }
            controls
            width="100%"
            height="100%"
          />
        )}
      </div>
      <div className="flex  flex-row flex-wrap items-center justify-between gap-6 rounded py-1.5 px-8 text-white  md:gap-10 md:bg-primary-100 md:text-black">
        <div className="flex flex-row flex-wrap items-center gap-6">
          <p className="text-sm font-[600]">{data?.name}</p>
          <div className="flex gap-1">
            {data?.genres?.map((item, index) => (
              <>
                <p className="text-xs font-[400]">{item.name}</p>
                {data?.genres?.length > 1 &&
                  data?.genres?.length !== index + 1 && (
                    <p className="text-xs font-[400]">|</p>
                  )}
              </>
            ))}
          </div>
          <p className="text-xs font-[400]">{data?.duration}</p>
          <p className="text-xs font-[400]">{data?.language}</p>
        </div>
        <div className="flex gap-6">
          {!access.access && (
            <button
              onClick={() => {
                if (user) {
                  if (!access?.access) {
                    setWatch(true);
                  }
                } else {
                  navigate("/login");
                }
              }}
              className="flex items-center gap-1 rounded bg-primary-200 px-5 py-2 text-xs font-semibold uppercase text-white shadow-primary2 hover:shadow-xl "
            >
              <BsFillPlayFill className="text-xl" />
              Watch Now
            </button>
          )}
          {user && (
            <button
              className="flex items-center gap-1 text-xl hover:text-primary2"
              onClick={() =>
                isWatchList
                  ? removeFromWatchListHanlder()
                  : addToWatchListHandler()
              }
            >
              {isWatchList ? (
                <>
                  <TbDeviceWatchOff />
                  <p className=" text-xs font-[400] ">Remove from Watchlist</p>
                </>
              ) : (
                <>
                  <TbDeviceWatchStats />
                  <p className=" text-xs font-[400] ">Add to Watchlist</p>
                </>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-col  gap-3 py-4 px-8 text-white md:pt-8 md:pb-5">
        <div className="flex flex-col gap-3">
          <p className="text-xs">
            Description:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.description?.slice(
                0,
                description ? data?.description?.length : 100
              )}
              <button
                className="ml-1 text-xs font-[400] text-primary-100"
                onClick={() => setDescription(!description)}
              >
                See {description ? "less" : "more"}
              </button>
            </span>
          </p>
          <p className="text-xs">
            Age-Limit:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.ageLimit}
            </span>
          </p>
          <p className="text-xs">
            Episodes:
            <br />
            <span className="text-xs font-[400] text-text">
              {data?.episodes?.length}
            </span>
          </p>
        </div>
        {/* {add episodes} */}

        {data.cast?.length > 0 && (
          <>
            <p className="text-xs">Cast:</p>
            <div className="flex flex-wrap gap-4 text-white">
              {data.cast?.map((item, index) => (
                <div className="flex flex-col  gap-2 text-xs">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="h-44 w-28 rounded-md object-cover"
                  />
                  <div>
                    <p>{item.name}</p>
                    <p className="text-text">{item.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <VideoCard
        episode
        data={{
          name: "Episodes",
          imageType: "Horizontal",
          content: data?.episodes?.map((item, index) => ({
            kind: "series",
            item: item,
          })),
        }}
        onClick={(item) => {
          setCurrentEpisode(item);
        }}
      />
      {similarSeries.length > 0 && (
        <VideoCard
          data={{
            name: "You might also like",
            imageType: "Vertical",
            content: similarSeries.map((item, index) => ({
              kind: "series",
              item: item,
            })),
          }}
        />
      )}
      {watch && (
        <MovieModal
          access={access}
          setWatch={setWatch}
          data={data}
          RentHandler={RentHandler}
        />
      )}
    </div>
  );
};

export default SeriesDetails;
