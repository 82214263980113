import React from "react";
import Static from "../../components/commen/Static";
import Input from "../../components/commen/Input";

const Contact = () => {
  return (
    <Static title={"Contact Us"}>
      <div className="mx-auto flex w-[90%] flex-col gap-6 rounded-lg bg-secondary3 p-5 md:w-2/3 xl:w-5/12">
        <h1 className="text-2xl font-bold text-text">Contact Form</h1>
        <div className="flex flex-col gap-6 md:flex-row">
          <Input placeholder={"First Name"} />
          <Input placeholder={"Last Name"} />
        </div>
        <div className="flex flex-col gap-6 md:flex-row">
          <Input placeholder={"Email"} />
          <Input placeholder={"Phone Number"} />
        </div>
        <textarea
          className="w-full rounded border border-text bg-transparent px-3 py-2.5 text-xs font-bold text-text outline-none focus:border-primary-100"
          placeholder="Message"
        />
        <button className="w-full rounded bg-primary-100 py-2.5 text-xs font-bold text-black">
          Submit
        </button>
        <div className="rounded bg-white p-3">
          <span className="font-bold underline">Address:</span> {""}
          403, Reliable Business Center near Om Heera Panna mall, Oshiwara,
          Jogeshwari West, Mumbai, Maharashtra - 400102
        </div>
      </div>
    </Static>
  );
};

export default Contact;
